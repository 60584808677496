












import { Component, Vue, Prop } from 'vue-property-decorator';
import Percent from '@/modules/common/types/percent.type';

@Component
export default class Occupancy extends Vue {
    @Prop({
        type: Number,
        default: 0,
    })
    valuePercent!: Percent;

    @Prop({
        type: Boolean,
        default: false,
    })
    isHorizontal!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    noTitle!: boolean;

    @Prop({
        type: String,
    })
    customTitle?: string;

    get title(): string {
        const defaultTitle = `OTB\n ${this.occupancy}%`;
        return this.customTitle || defaultTitle;
    }

    get occupancy() {
        return (this.valuePercent * 100).toFixed();
    }

    get className() {
        return this.isHorizontal ? 'horizontal' : '';
    }

    get value() {
        const elHeight = 40;
        return elHeight - this.valuePercent * elHeight;
    }

    get top() {
        return this.isHorizontal ? 0 : this.value;
    }

    get right() {
        return this.isHorizontal ? this.value : 0;
    }
}
