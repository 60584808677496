




import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Flag extends Vue {
    @Prop({
        required: true,
        type: String,
    })
    private countryCode?: string;
}
