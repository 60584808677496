/* eslint no-underscore-dangle: 0 */
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { mixins, HorizontalBar } from 'vue-chartjs';
import Chart, {
    ChartData, ChartOptions, ChartTooltipOptions, ChartPluginsOptions,
} from 'chart.js';

export interface TooltipModel extends ChartTooltipOptions {
    opacity: number,
    caretX: number,
    title: string[]
}

@Component({
    extends: HorizontalBar,
    mixins: [mixins.reactiveProp],
})
export default class GraphBar extends Vue {
    @Prop({ required: true, type: Object as () => ChartData })
    chartData!: ChartData;

    @Prop({ required: true, type: Object as () => ChartOptions })
    options!: ChartOptions;
    @Watch('options')
    onOptionsChanged() {
        this.drawChart();
    }

    public renderChart!: (chartData: ChartData, options: ChartOptions) => void;
    public addPlugin!: (plugin: ChartPluginsOptions) => void;

    mounted() {
        this.drawChart();
    }

    drawChart() {
        if (this.chartData && this.chartData.datasets && this.chartData.datasets.length !== 0) {
            this.renderChart(this.chartData, this.options);
        }
    }
}
