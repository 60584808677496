




























































import { Prop, Vue, Component } from 'vue-property-decorator';

export interface Item {
    name: string,
    value: string | number,
    isSelected?: boolean,
    isAutoscan?: boolean,
    disabled?: boolean
}

@Component
export default class CustomCheckboxSelect extends Vue {
    @Prop({
        required: false,
        type: Number,
        default: 0,
    })
    private maxSelected!: number;

    @Prop({
        required: false,
        type: Number,
        default: 0,
    })
    private minSelected!: number;

    @Prop({
        required: true,
        type: Array as () => Item[],
    })
    private items!: Item[];

    @Prop({
        required: false,
    })
    private value!: Item;

    @Prop({
        required: false,
        type: String,
    })
    private label!: string;

    @Prop({
        required: false,
        type: Boolean,
        default: true,
    })
    private showButton!: boolean;

    @Prop({
        required: false,
        type: Boolean,
        default: true,
    })
    private showSelected!: boolean;

    @Prop({
        type: String,
        required: false,
    })
    private title?: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private disabled!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private closeAfterSelect!: boolean;

    @Prop({
        type: Boolean,
    })
    private selectMode!: boolean;

    private isExpanded: boolean = false;

    get itemsList() {
        return this.items;
    }

    get currentValue() {
        return this.value.value;
    }

    get disabledLength() {
        const reducer = (accumulator: number, currentValue: Item) => (currentValue.disabled ? accumulator + 1 : accumulator);
        return this.items.reduce(reducer, 0);
    }

    get maxCheckItems() {
        return this.maxSelected;
    }

    get isSelectMode() {
        return this.selectMode;
    }

    clickOutside(e: MouseEvent) {
        const ref = this.$refs.multiSelect as HTMLDivElement;

        if (ref && (ref !== e.target && !ref.contains(e.target as Node | null))) {
            this.isExpanded = false;
            document.body.removeEventListener('click', this.clickOutside);
        }
    }

    hide() {
        this.isExpanded = false;
    }

    isSelected(item: Item) {
        return item.value === this.currentValue;
    }

    isCheckBoxSelected(item: Item) {
        return item.isSelected;
    }

    isAutoscan(item: Item) {
        return item.isAutoscan;
    }

    handleSelectClick() {
        if (this.disabled) return;

        this.isExpanded = !this.isExpanded;
        if (this.isExpanded) {
            document.body.addEventListener('click', this.clickOutside, true);
        } else {
            document.body.removeEventListener('click', this.clickOutside, true);
        }

        this.updateListPosition();
    }

    handleOptionClick(clickedValue: Item) {
        return this.isSelectMode ? this.handleSelectbox(clickedValue) : this.handleCheckbox(clickedValue);
    }

    handleSelectbox(clickedValue: Item) {
        if (this.closeAfterSelect) {
            this.handleSelectClick();
        }

        this.$emit('input', clickedValue);
    }

    handleCheckbox(clickedValue: Item) {
        if (clickedValue.isSelected && this.items.filter(item => item.isSelected).length <= this.minSelected) {
            this.$emit('error', { minSelected: true });
            return;
        }
        if (!clickedValue.isSelected && this.items.filter(item => item.isSelected).length === this.maxSelected) {
            this.$emit('error', { maxSelected: true });
            return;
        }

        this.$emit('checkbox', clickedValue.value);
    }

    private updateListPosition() {
        const { wrapper, multiSelect } = this.$refs as { [k: string]: HTMLDivElement };

        if (!wrapper || !multiSelect) return;

        const { top, left, height } = multiSelect.getBoundingClientRect();
        wrapper.style.left = `${left}px`;
        wrapper.style.top = `${top + height}px`;
    }

    public mounted() {
        window.addEventListener('scroll', this.updateListPosition);
    }

    public beforeDestroy() {
        window.removeEventListener('scroll', this.updateListPosition);
    }
}
