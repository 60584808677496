






















import { Inject } from 'inversify-props';
import { Vue, Component } from 'vue-property-decorator';

import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import SCAN_STATUS from '@/modules/rates/constants/scan-status.constant';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import ScanBtn from '@/modules/common/components/ui-kit/scan-btn.vue';
import FEATURES from '@/modules/common/constants/features.constant';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';

import PromotionsHintTooltip from './promotions-hint-tooltip.vue';
import PromotionsService, { PromotionsServiceS } from '../promotions.service';

@Component({
    components: {
        DateDocumentFilter,
        PromotionsHintTooltip,
        ScanBtn,
    },
})
export default class PromotionsHeader extends Vue {
    @Inject(PromotionsServiceS)
    private promotionsService!: PromotionsService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Inject(UserServiceS)
    private userService!: UserService;

    @Inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    isTriggeringScan = false;

    get lastScanDate() {
        const { data } = this.promotionsService;

        if (!data) return null;

        return data.lastScanDate;
    }

    get isScanning() {
        return this.promotionsService.scanStatus === SCAN_STATUS.IN_PROGRESS;
    }

    get isGraphView() {
        if (!this.$route.name) return false;
        return this.$route.name!.includes('promotions.graph');
    }

    get isTableView() {
        if (!this.$route.name) return false;
        return this.$route.name!.includes('promotions.table');
    }

    get isAnalysisView() {
        if (!this.$route.name) return false;
        return this.$route.name!.includes('analysis');
    }

    get isMainView() {
        return !this.isGraphView && !this.isTableView && !this.isAnalysisView;
    }

    get isPrevMonth() {
        return this.documentFiltersService.isPreviousMonth;
    }

    get isScanFeatureEnabled() {
        return this.promotionsService.isScanFeatureEnabled && !this.isTriggeringScan;
    }

    public triggerScan() {
        this.isTriggeringScan = true;
        this.promotionsService
            .triggerScan(undefined, this.isMainView)
            .finally(() => {
                this.isTriggeringScan = false;
            });
    }

    get hotelName() {
        const { currentHotelId } = this.userService;

        if (!currentHotelId) {
            return null;
        }
        return this.hotelsService.getHotelName(currentHotelId);
    }

    get feature() {
        return FEATURES.PROMOTION_DETECTION;
    }
}
