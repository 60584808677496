import { render, staticRenderFns } from "./ci-table-active-row.vue?vue&type=template&id=27d2eca8&scoped=true&"
import script from "./ci-table-active-row.vue?vue&type=script&lang=ts&"
export * from "./ci-table-active-row.vue?vue&type=script&lang=ts&"
import style0 from "./ci-table-active-row.vue?vue&type=style&index=0&id=27d2eca8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27d2eca8",
  null
  
)

export default component.exports