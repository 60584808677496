
























































































import { Component, Mixins } from 'vue-property-decorator';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import PosDocumentFilter from '@/modules/document-filters/components/pos-document-filter.vue';
import LosDocumentFilter from '@/modules/document-filters/components/los-document-filter.vue';
import AnalysisBtn from '@/modules/common/components/ui-kit/analysis-btn.vue';
import { PromotionGraphType } from '../promotions.store';
import PromotionsFiltersMixin from '../mixins/promotions-filter.mixin';

@Component({
    components: {
        CustomSelect,
        PosDocumentFilter,
        LosDocumentFilter,
        AnalysisBtn,
    },
})
export default class PromotionsActions extends Mixins(PromotionsFiltersMixin) {
    get graphType() {
        return this.promotionsService.graphType;
    }

    set graphType(value: PromotionGraphType) {
        this.promotionsService.graphType = value;
    }

    get isGraphView() {
        if (!this.$route.name) return false;
        return this.$route.name!.includes('promotions.graph');
    }

    get isTableView() {
        if (!this.$route.name) return false;
        return this.$route.name!.includes('promotions.table');
    }

    get isAnalysisView() {
        if (!this.$route.name) return false;
        return this.$route.name!.includes('analysis');
    }

    get isMainView() {
        return !this.isGraphView && !this.isTableView && !this.isAnalysisView;
    }

    get currentView() {
        if (!this.$route.name) return '';
        return this.$route.name.split('.').pop();
    }

    get graphRoute() {
        return {
            name: 'hotel.promotions.graph',
            params: {
                ...this.$route.params,
            },
        };
    }

    get mainRoute() {
        return {
            name: 'hotel.promotions',
            params: {
                ...this.$route.params,
            },
        };
    }

    get tableRoute() {
        return {
            name: 'hotel.promotions.table',
            params: {
                ...this.$route.params,
            },
        };
    }

    currentCompset(compsetId: string) {
        const compset = this.compsetsService.getCompset(compsetId);
        if (!compset) {
            return null;
        }
        return compset.name;
    }
}
