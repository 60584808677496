


















import { Inject } from 'inversify-props';
import { Vue, Component, Prop } from 'vue-property-decorator';
import PromotionsService, { PromotionsServiceS } from '../promotions.service';

@Component
export default class PromotionsMark extends Vue {
    @Inject(PromotionsServiceS)
    private promotionsService!: PromotionsService;

    public get cornerValue() {
        const { program, provider, hotelId } = this;

        if (!program.startsWith('percentage')) return undefined;

        const averagePercent = this.getProgramAveragePercent(provider, program, +hotelId);
        if (!averagePercent) return undefined;

        return `${averagePercent.toFixed(2)}%`;
    }

    public get daysCount() {
        const { program, provider, hotelId } = this;

        return this.promotionsService
            .getProgramActiveDays(provider, program, +hotelId);
    }

    public get status() {
        if (!this.isMainHotel) return 'disabled';
        const { program, provider, hotelId } = this;
        const { competitors } = this.promotionsService;

        const percentageComparison = program.startsWith('percentage');

        const mainValue = percentageComparison
            ? this.promotionsService
                .getProgramAveragePercent(provider, program, hotelId)
            : this.promotionsService
                .getProgramActiveDays(provider, program, hotelId);

        const averageSum = this.promotionsService.competitors
            .map(competitorHotelId => {
                const compareValue = percentageComparison
                    ? this.promotionsService
                        .getProgramAveragePercent(provider, program, competitorHotelId)
                    : this.promotionsService
                        .getProgramActiveDays(provider, program, competitorHotelId);

                return compareValue || 0;
            })
            .reduce((prev, curr) => prev + curr, 0);

        const averageValue = (averageSum / competitors.length) || 0;

        if (mainValue === 0 && averageValue === 0) {
            return 'disabled';
        }

        if (averageValue === mainValue) {
            return 'yellow';
        }

        return averageValue < mainValue
            ? 'green'
            : 'red';
    }

    public get isMainHotel() {
        return this.currentHotelId === this.hotelId;
    }

    public get isNewProgram() {
        const { provider, program, hotelId } = this;

        return this.promotionsService
            .isProgramNew(provider, program, hotelId);
    }

    private get currentHotelId() {
        return +this.$route.params.hotelId;
    }

    public getProgramAveragePercent = this.promotionsService
        .getProgramAveragePercent.bind(this.promotionsService);

    @Prop({
        type: Number,
        default: null,
    })
    private hotelId!: number;

    @Prop({
        type: String,
        default: '',
    })
    public program!: string;

    @Prop({
        type: String,
        default: '',
    })
    public provider!: string;
}
