





import EventsModel from '@/modules/events/models/events.model';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import Day from '@/modules/common/types/day.type';
import CalendarEvents from '@/modules/common/components/ui-kit/calendar-events.vue';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

@Component({
    components: { CalendarEvents },
})
export default class CalendarEventsContainer extends Vue {
    @Inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;
    @Inject(UserServiceS) userService!: UserService;

    @Prop({
        type: Number,
        required: true,
    })
    private day!: Day;

    @Prop({
        type: String,
        required: false,
        default: '',
    })
    private country!: string;

    @Prop({
        type: String,
        default: '',
    })
    public emptyText!: string;

    @Prop({
        type: Array,
        required: false,
        default: () => [],
    })
    private countries!: string[];

    get isReady() {
        return this.eventsData && this.eventsData.length;
    }

    get hasLocalEvents(): boolean {
        const country = this.countries.length ? this.countries.join(',') : this.country;
        if (this.countries.length) {
            return this.eventsManagerService.hasLocalEventsByDay({ day: this.day, country }, { countries: this.countries });
        }
        return this.eventsManagerService.hasLocalEventsByDay({ day: this.day, country });
    }

    get eventsData(): EventsModel[] {
        const country = this.countries.length ? this.countries.join(',') : this.country;
        if (this.countries.length) {
            return this.eventsManagerService.getCountryEventsByDay({ day: this.day, country }, { countries: this.countries });
        }
        return this.eventsManagerService.getCountryEventsByDay({ day: this.day, country });
    }
}
