import { Component, Vue } from 'vue-property-decorator';

let isDragging = false;

@Component
export default class DragScrollMixin extends Vue {
    public startDragScroll(e: MouseEvent) {
        const container = e.currentTarget as HTMLElement;
        e.preventDefault();

        const handleMouseMove = (me: MouseEvent) => {
            container.scrollLeft -= me.movementX;
            container.scrollTop -= me.movementY;
            container.style.cursor = 'grabbing !important';
            isDragging = true;
        };

        const disableDragMode = () => {
            window.removeEventListener('mousemove', handleMouseMove);
            container.style.cursor = '';
            setTimeout(() => { isDragging = false; });
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', disableDragMode, { once: true, capture: true });
    }

    public isDragging() {
        return isDragging;
    }
}
