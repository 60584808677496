









































import { Component, Vue } from 'vue-property-decorator';

import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import PromotionsHeader from '../components/promotions-header.vue';
import PromotionsMarkTable from '../components/promotions-mark-table.vue';
import PromotionsActions from '../components/promotions-actions.vue';
import PromotionsGraph from '../components/promotions-graph.vue';
import PromotionsTable from '../components/promotions-table.vue';
import PromotionsAnalysisTable from '../components/promotions-analysis-table';

@Component({
    components: {
        PromotionsHeader,
        PromotionsMarkTable,
        PromotionsActions,
        PromotionsGraph,
        PromotionsTable,
        PromotionsAnalysisTable,
        PageWrapper,
    },
})
export default class Promotions extends Vue {
    get isGraphView() {
        if (!this.$route.name) return false;
        return this.$route.name!.includes('promotions.graph');
    }

    get isTableView() {
        if (!this.$route.name) return false;
        return this.$route.name!.includes('promotions.table');
    }

    get isMainView() {
        return !this.isGraphView && !this.isTableView && !this.isAnalysisTableView;
    }

    get isAnalysisTableView() {
        if (!this.$route.name) return false;
        return this.$route.name!.includes('promotions.analysis.table');
    }
}
