





































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
    ITableData, ITableConfig, ITooltip, ICellSize,
} from './interfaces';
import CiTableTooltip from './ci-table-tooltip.vue';
import CiTableActiveRow from './ci-table-active-row.vue';
import CiTableColumn from './ci-table-column.vue';

interface HoverInfo {
    rowIndex: number;
    columnIndex: number | number[];
    isSubColumn: boolean;
    domRect: DOMRect;
}

@Component({
    components: {
        CiTableColumn,
        CiTableTooltip,
        CiTableActiveRow,
    },
})
export default class CiTable extends Vue {
    @Prop({
        required: true,
        type: Array,
    })
    tableData!: ITableData;

    @Prop({
        required: true,
    })
    tableConfig!: ITableConfig;

    @Prop({
        default: null,
    })
    date!: Date;

    @Prop({
        type: Boolean,
        default: true,
    })
    isLoading!: boolean;

    private isMouseDown: boolean = false;
    private activeRow: {
        height: string | null
        offset: number | null
        scrollOffset: number | null
    } = {
        height: null,
        offset: null,
        scrollOffset: null,
    };
    private tooltipData: ITooltip = {
        title: null,
        items: null,
        elementRect: null,
        isVisible: false,
    };

    private disabledColumns: string[] = [];

    mounted() {
        window.addEventListener('mouseup', this.handleMouseUp);

        const { tableSection, tableSectionSticky } = this.$refs as { [key: string]: Element[] };
        if (!tableSectionSticky || !tableSection) {
            return;
        }

        tableSection[0].addEventListener('scroll', this.handleScroll);
    }

    beforeDestroy() {
        window.removeEventListener('mouseup', this.handleMouseUp);

        const { tableSection, tableSectionSticky } = this.$refs as { [key: string]: Element[] };
        if (!tableSectionSticky || !tableSection) {
            return;
        }

        if (!tableSection[0]) return;
        tableSection[0].removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const { tableSection, tableSectionSticky } = this.$refs as { [key: string]: Element[] };
        tableSectionSticky[0].scrollTo({ top: tableSection[0].scrollTop });
        this.activeRow = {
            ...this.activeRow,
            scrollOffset: tableSection[0].scrollTop,
        };
    }

    handleMouseMove(e: MouseEvent) {
        e.preventDefault();
        const { tableSection } = this.$refs as { [key: string]: Element[] };

        if (!tableSection[0]) return;
        tableSection[0].scrollTo({ left: tableSection[0].scrollLeft - e.movementX });
    }

    handleMouseDown() {
        this.isMouseDown = true;
        window.addEventListener('mousemove', this.handleMouseMove);
    }

    handleMouseUp() {
        if (!this.isMouseDown) {
            return;
        }

        this.isMouseDown = false;
        window.removeEventListener('mousemove', this.handleMouseMove);
    }

    handleRowHover(hoverInfo: HoverInfo, tableIndex: number) {
        const { rowIndex, columnIndex } = hoverInfo;
        const { isSubColumn, domRect: cellDomRect } = hoverInfo;

        this.$emit('hoverRow', { tableIndex, hoverInfo });

        let tableRow = 1 + rowIndex;

        if (isSubColumn) {
            tableRow += 1;
        }

        let activeRowHeight = '0';
        let activeRowOffset = 0;

        let cellSize: ICellSize;

        const { tableSection } = this.$refs as { [key: string]: Element[] };

        if (!this.tableConfig.cellSize) {
            activeRowHeight = '50px';
            activeRowOffset = tableRow * 50;
            this.activeRow = {
                height: '50px',
                offset: tableRow * 50,
                scrollOffset: tableSection[0].scrollTop,
            };
        } else {
            if (Array.isArray(this.tableConfig.cellSize)) {
                [cellSize] = this.tableConfig.cellSize;
            } else {
                ({ cellSize } = this.tableConfig);
            }

            const { length } = cellSize.height;
            const configRowIndex = tableRow > length - 1 ? length - 1 : tableRow;

            activeRowHeight = cellSize.height[configRowIndex];

            for (let i = 0; i < tableRow; i += 1) {
                let configIndex = i;
                if (i >= length) {
                    configIndex = configRowIndex;
                }
                activeRowOffset += parseInt(cellSize.height[configIndex], 10);
            }

            this.activeRow = {
                height: activeRowHeight,
                offset: activeRowOffset,
                scrollOffset: tableSection[0].scrollTop,
            };
        }
    }

    handleMouseLeave() {
        this.activeRow = {
            height: null,
            offset: null,
            scrollOffset: null,
        };
        this.tooltipData = {
            ...this.tooltipData,
            isVisible: false,
        };
    }

    handleWheel(e: WheelEvent) {
        const { currentTarget } = e;
        if (!/sticky/.test((currentTarget as HTMLElement).className)) {
            return;
        }

        e.preventDefault();
        const { tableSection } = this.$refs as { [key: string]: Element[] };
        tableSection[0].scrollTo({ top: tableSection[0].scrollTop + e.deltaY });
    }

    handleTooltipChange(e: ITooltip) {
        this.tooltipData = { ...e };
    }

    handleDisableColumns(columnTitiles: string[]) {
        this.disabledColumns = [...columnTitiles];
        this.$emit('columnDisabled', this.disabledColumns);
    }

    cellSize(configIndex: number) {
        if (!this.tableConfig.cellSize) {
            return undefined;
        }

        if (!Array.isArray(this.tableConfig.cellSize)) {
            return this.tableConfig.cellSize as ICellSize;
        }

        if (configIndex > (this.tableConfig.cellSize.length - 1)) {
            return this.tableConfig.cellSize[this.tableConfig.cellSize.length - 1];
        }

        return this.tableConfig.cellSize[configIndex];
    }

    isColumnDisabled(title: string) {
        return this.disabledColumns.find(disabledColumn => disabledColumn === title);
    }
}
