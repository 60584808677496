import moment, { Moment } from 'moment';

export default function formatDate(source: Date | null) {
    if (!source) {
        return '--/--/--';
    }

    const currentDate = moment();

    const today = currentDate.clone().startOf('day');
    const yesterday = currentDate.clone().subtract(1, 'days').startOf('day');
    const sourceRef = moment(source);

    let todayOrYesterday = '';
    if (sourceRef.isSame(yesterday, 'd')) {
        todayOrYesterday = 'Yesterday';
    } else if (sourceRef.isSame(today, 'd')) {
        todayOrYesterday = 'Today';
    }

    if (todayOrYesterday) {
        return `${todayOrYesterday}, ${sourceRef.format('LT')}`;
    }

    return sourceRef.format('D/M/YYYY');
}
