var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:( _obj = {
        'custom-tooltip': true,
        'custom-tooltip--active': _vm.active
    }, _obj['custom-tooltip--' + _vm.side] = true, _obj ),style:({
        '--tooltip-width': _vm.width,
        '--tooltip-vertical-offset': _vm.verticalOffset + 'px',
    })},[(_vm.$slots.hoverContent)?_c('div',{staticClass:"custom-tooltip__hover-trigger"},[_vm._t("hoverContent")],2):_vm._e(),_c('div',{ref:"container",class:( _obj$1 = {
            'custom-tooltip__container': true
        }, _obj$1[_vm.tooltipClass] = true, _obj$1 )},[(_vm.$slots.headline)?_c('div',{staticClass:"custom-tooltip__headline"},[_vm._t("headline")],2):_vm._e(),_c('div',{staticClass:"custom-tooltip__content"},[_vm._t("default")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }