





















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';

const images = require.context('@/modules/common/assets/', true, /\.png$|\.jpg$|\.svg$/);
const MOBILE_DEVICE_ICON = images('./mobile-icon.svg');
const DESKTOP_DEVICE_ICON = images('./desktop-icon.svg');

@Component
export default class ProviderCard extends Vue {
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(HelperServiceS) private helperService!: HelperService;

    @Prop({
        type: [String, Array],
    })
    provider!: string | string[];

    @Prop({
        type: Boolean,
        default: true,
    })
    hasLogo!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    skeleton!: boolean;

    get isList() {
        return typeof this.provider !== 'string';
    }

    get logo() {
        if (typeof this.provider !== 'string') {
            return this.provider
                .map(p => this.helperService.providerLogo(p));
        }

        switch (this.provider) {
            case 'rentalcars':
                return images('./broker-logos/rentalcars.svg');
            case 'autoeurope':
                return images('./broker-logos/autoeurope.svg');
            default:
                return this.helperService.providerLogo(this.provider);
        }
    }

    getProviderLabel(providerName: string) {
        const { data } = this.providersService;

        if (this.userService.isCarUser) {
            return providerName;
        }

        if (!data) {
            return providerName;
        }

        const provider = data.find(currentProvider => currentProvider.name === providerName);

        if (!provider) {
            return '';
        }

        return provider.label || provider.name || '';
    }

    getProviderDeviceIcon(providerName: string) {
        if (!providerName) return '';
        return providerName.includes('mobile')
            ? MOBILE_DEVICE_ICON
            : DESKTOP_DEVICE_ICON;
    }
}
