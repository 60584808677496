/* eslint-disable no-underscore-dangle */
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { mixins, Bar, generateChart } from 'vue-chartjs';
import {
    Chart,
    ChartData,
    ChartOptions,
} from 'chart.js';

// draws a rectangle with a rounded top
Chart.helpers.drawRoundedTopRectangle = (ctx: any, x: number, y: number, width: number, height: number, radius: number) => {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    // top right corner
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);

    ctx.lineTo(x + width, y + height);
    // bottom left corner
    ctx.lineTo(x, y + height);
    // top left
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
};

// @ts-ignore
Chart.elements.RoundedTopRectangle = Chart.elements.Rectangle.extend({
    draw() {
        const { data: { datasets } } = this._chart as Chart;
        const ctx = this._chart.ctx!;
        const value = datasets![this._datasetIndex].data![this._index]!;
        const vm = this._view;
        let left;
        let right;
        let top;
        let bottom;
        let signX;
        let signY;
        let borderSkipped;
        let { borderWidth } = vm;

        if (!vm.horizontal) {
            // bar
            left = vm.x - vm.width / 2;
            right = vm.x + vm.width / 2;
            top = vm.y;
            bottom = vm.base;
            signX = 1;
            signY = bottom > top ? 1 : -1;
            borderSkipped = vm.borderSkipped || 'bottom';
        } else {
            // horizontal bar
            left = vm.base;
            right = vm.x;
            top = vm.y - vm.height / 2;
            bottom = vm.y + vm.height / 2;
            signX = right > left ? 1 : -1;
            signY = 1;
            borderSkipped = vm.borderSkipped || 'left';
        }

        // Canvas doesn't allow us to stroke inside the width so we can
        // adjust the sizes to fit if we're setting a stroke on the line
        if (borderWidth) {
            // borderWidth shold be less than bar width and bar height.
            const barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
            borderWidth = borderWidth > barSize ? barSize : borderWidth;

            const halfStroke = borderWidth / 2;

            // Adjust borderWidth when bar top position is near vm.base(zero).
            const borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
            const borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
            const borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
            const borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);

            // not become a vertical line?
            if (borderLeft !== borderRight) {
                top = borderTop;
                bottom = borderBottom;
            }

            // not become a horizontal line?
            if (borderTop !== borderBottom) {
                left = borderLeft;
                right = borderRight;
            }
        }

        // calculate the bar width and roundess
        const barWidth = Math.abs(left - right);

        // eslint-disable-next-line no-underscore-dangle
        const roundness = this._chart.config.options.barRoundness || 0.5;
        const radius = barWidth * roundness * 0.5;

        // keep track of the original top of the bar
        const prevTop = top;

        // move the top down so there is room to draw the rounded top
        top = prevTop + radius;
        const barRadius = top - prevTop;

        if (value !== 0) {
            ctx.beginPath();
            ctx.fillStyle = vm.backgroundColor;
            ctx.strokeStyle = vm.borderColor;
            ctx.lineWidth = borderWidth;

            // draw the rounded top rectangle
            Chart.helpers.drawRoundedTopRectangle(ctx, left, (top - barRadius + 1), barWidth, bottom - prevTop, barRadius);

            ctx.fill();
            if (borderWidth) {
                ctx.stroke();
            }
        } else {
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(left, bottom);
            ctx.lineTo(left + barWidth, bottom);

            ctx.strokeStyle = '#aaa';
            ctx.stroke();
            ctx.restore();
        }

        // restore the original top value so tooltips and scales still work
        top = prevTop;
    },
});

Chart.defaults.roundedBar = Chart.helpers.clone(Chart.defaults.bar);

Chart.controllers.roundedBar = Chart.controllers.bar.extend({
    // @ts-ignore
    dataElementType: Chart.elements.RoundedTopRectangle,
});

const RoundedBar = generateChart('rounded-bar', 'roundedBar');

@Component({
    extends: RoundedBar,
    mixins: [mixins.reactiveProp],
})
export default class RoundedBarGraph extends Vue {
    @Prop({ required: true, type: Object as () => ChartData })
    chartData!: ChartData;

    @Prop({ required: true, type: Object as () => ChartOptions })
    options!: ChartOptions;
    @Watch('options')
    onOptionsChanged() {
        this.drawChart();
    }

    public renderChart!: (chartData: ChartData, options: ChartOptions) => void;

    mounted() {
        this.drawChart();
    }

    drawChart() {
        if (this.chartData && this.chartData.datasets && this.chartData.datasets.length !== 0) {
            this.renderChart(this.chartData, this.options);
        }
    }
}
