/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { mixins, Doughnut } from 'vue-chartjs';
import Chart, {
    ChartData,
    ChartOptions,
    ChartTooltipOptions,
    ChartPluginsOptions,
} from 'chart.js';

Chart.controllers.doughnut.prototype.setHoverStyle = function setHoverStyle(arc: any) {
    const model = arc._model;
    const options = arc._options;

    arc.$previousStyle = {
        backgroundColor: model.backgroundColor,
        shadow: 0,
    };

    model.backgroundColor = options.hoverBackgroundColor || options.backgroundColor;
    model.shadow = 4;
};

// @ts-ignore
Chart.elements.Arc.prototype.draw = function draw() {
    const TAU = Math.PI * 2;
    const { ctx } = this._chart;
    const vm = this._view;
    if (vm.shadow === undefined) {
        vm.shadow = 0;
    }
    const pixelMargin = (vm.borderAlign === 'inner') ? 0.33 : 0;
    const arc = {
        x: vm.x,
        y: vm.y,
        innerRadius: vm.innerRadius,
        outerRadius: Math.max(vm.outerRadius - pixelMargin, 0),
        pixelMargin,
        startAngle: vm.startAngle,
        endAngle: vm.endAngle,
        fullCircles: Math.floor(vm.circumference / TAU),
    };

    let i;

    ctx.save();

    ctx.fillStyle = vm.backgroundColor;
    ctx.strokeStyle = vm.borderColor;
    const diffAngle = vm.startAngle - (vm.startAngle - vm.endAngle) * 0.5;

    ctx.translate(
        Math.cos(diffAngle) * vm.shadow,
        Math.sin(diffAngle) * vm.shadow,
    );

    ctx.shadowColor = '#0005';
    ctx.shadowBlur = vm.shadow;
    ctx.shadowOffsetX = -Math.cos(diffAngle) * vm.shadow;
    ctx.shadowOffsetY = -Math.sin(diffAngle) * vm.shadow;

    if (arc.fullCircles) {
        arc.endAngle = arc.startAngle + TAU;

        ctx.beginPath();
        ctx.arc(arc.x, arc.y, arc.outerRadius, arc.startAngle, arc.endAngle);
        ctx.arc(arc.x, arc.y, arc.innerRadius, arc.endAngle, arc.startAngle, true);
        ctx.closePath();

        for (i = 0; i < arc.fullCircles; ++i) {
            ctx.fill();
        }

        arc.endAngle = arc.startAngle + (vm.circumference % TAU);
    }

    ctx.beginPath();
    ctx.arc(arc.x, arc.y, arc.outerRadius, arc.startAngle, arc.endAngle);
    ctx.arc(arc.x, arc.y, arc.innerRadius, arc.endAngle, arc.startAngle, true);
    ctx.closePath();
    ctx.fill();

    ctx.strokeStyle = '#fff';
    ctx.stroke();

    ctx.restore();
};

export interface TooltipModel extends ChartTooltipOptions {
    opacity: number,
    caretX: number,
    title: string[]
}

@Component({
    extends: Doughnut,
    mixins: [mixins.reactiveProp],
})
export default class GraphBar extends Vue {
    @Prop({ required: true, type: Object as () => ChartData })
    chartData!: ChartData;

    @Prop({ required: true, type: Object as () => ChartOptions })
    options!: ChartOptions;
    @Watch('options')
    onOptionsChanged() {
        this.drawChart();
    }

    public renderChart!: (chartData: ChartData, options: ChartOptions) => void;
    public addPlugin!: (plugin: ChartPluginsOptions) => void;

    mounted() {
        this.drawChart();
    }

    drawChart() {
        if (this.chartData
            && this.chartData.datasets
            && this.chartData.datasets.length !== 0) {
            this.renderChart(this.chartData, this.options);
        }
    }
}
