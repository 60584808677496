import { Inject } from 'inversify-props';
import { Vue, Component } from 'vue-property-decorator';

import CompsetModel from '@/modules/compsets/models/compset.model';
import Item from '@/modules/common/interfaces/item.interface';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import PromotionsService, { PromotionsServiceS } from '../promotions.service';
import { PromotionGraphType } from '../promotions.store';

@Component
export default class PromotionsFiltersMixin extends Vue {
    @Inject(CompsetsServiceS)
    protected compsetsService!: CompsetsService;

    @Inject(PromotionsServiceS)
    protected promotionsService!: PromotionsService;

    @Inject(ProvidersServiceS)
    protected providersService!: ProvidersService;

    @Inject(DocumentFiltersServiceS)
    protected documentFiltersService!: DocumentFiltersService;

    protected graphTypeItems = [
        {
            value: PromotionGraphType.DEALS,
            name: 'Deals',
        },
        {
            value: PromotionGraphType.PROGRAMS,
            name: 'Programs',
        },
    ];

    protected get provider() {
        return this.promotionsService.provider;
    }

    protected set provider(value: string | null) {
        this.promotionsService.provider = value;
    }

    protected get graphType() {
        return this.promotionsService.graphType;
    }

    protected set graphType(value: PromotionGraphType) {
        this.promotionsService.graphType = value;
    }

    protected get providerItems() {
        const { data } = this.promotionsService;

        if (!data) return [];

        const list = Object
            .keys(data.providerData)
            .map(provider => ({
                name: this.providersService.getProviderLabel(provider),
                value: provider,
            }));

        if (!this.provider) {
            this.provider = list[0].value;
        }

        return list;
    }

    protected get viewItems() {
        const { data, provider } = this.promotionsService;
        if (!data || !provider) return [];

        const programList = data.promotions[provider]
            .filter(program => (this.graphType === PromotionGraphType.DEALS
                ? program.startsWith('percentage')
                : !program.startsWith('percentage')));

        if (!programList.includes(this.programView)) {
            this.programView = '';
        }

        const items = programList
            .map(program => ({
                name: this.promotionsService
                    .getProgramLabel(provider, program),
                value: program,
            }));

        const isAnalysisView = (() => {
            if (!this.$route.name) return false;
            return this.$route.name!.includes('analysis');
        })();

        if (!isAnalysisView) {
            if (items.length > 1) {
                items.splice(0, 0, {
                    name: 'All',
                    value: '',
                });
            } else {
                this.programView = items[0].value;
            }
        } else if (this.programView === '') {
            this.programView = items[0].value;
        }

        return items;
    }

    protected get compsetItems(): Item[] {
        if (!this.compsetsService.compsets) {
            return [];
        }

        const getCompsetTypeLabel = (compset: CompsetModel) => this
            .$t(`filterSettings.compset.${compset.type}`);

        return this.compsetsService.compsets
            .map((compset: CompsetModel) => ({
                value: compset.id,
                name: `${compset.name} (${getCompsetTypeLabel(compset)})`,
            }));
    }

    get compsetId() {
        return this.documentFiltersService.storeState.settings.compsetId;
    }

    set compsetId(value) {
        if (value) {
            this.documentFiltersService.updateCompset(value);
        }
    }

    get programView() {
        return this.promotionsService.programView;
    }

    set programView(value: string) {
        this.promotionsService.programView = value;
    }
}
