



import { Vue, Prop, Component } from 'vue-property-decorator';

import Day from '@/modules/common/types/day.type';
import CustomTooltip from '@/modules/common/components/ui-kit/custom-tooltip.vue';

let prevDay = -2;
let prevEl: HTMLElement | undefined;

export enum TooltipSide {
    LEFT = 'left',
    RIGHT = 'right',
}

@Component({
    components: {
        CustomTooltip,
    },
})
export default class DayTooltipTemplate extends Vue {
    @Prop({
        type: Number,
        default: 0,
    })
    public day!: Day;

    @Prop({
        type: HTMLElement,
        default: null,
    })
    public focusElement?: HTMLElement;

    public tooltipSide: TooltipSide = TooltipSide.LEFT;
    tooltipVerticalOffset = 0;

    protected defaultZIndex = 200009;

    protected defaultStyles = {
        position: 'fixed',
        pointerEvents: 'none',
    };

    private currentScrollContainer?: HTMLElement | Window;
    protected reversedChangingSide = false;

    coords = {
        ...this.defaultStyles,
        left: '',
        top: '',
        width: '',
        height: '',
        zIndex: this.defaultZIndex,
    };

    get isActive() {
        return !!this.day && !!this.focusElement;
    }

    get attrs() {
        return {
            active: this.isActive,
            style: this.coords,
            side: this.tooltipSide,
            verticalOffset: this.tooltipVerticalOffset,
            ref: 'tooltip',
        };
    }

    async updateTooltipPosition(element = this.focusElement) {
        // NOTE: A hack to get actual position of graph's tooltip,
        //       since the initial position is 0,0
        await new Promise(r => setTimeout(r));

        if (!element) {
            return;
        }

        const { tooltip } = this.$refs as unknown as {
            tooltip: {
                getContainer(): HTMLDivElement
            }
        };

        if (!tooltip) return;

        const container = tooltip.getContainer();
        const bbox = element.getBoundingClientRect() as DOMRect;

        let { x, y } = bbox;
        let { width, height } = bbox;

        x = x || parseFloat(this.coords.left);
        y = y || parseFloat(this.coords.top);
        width = width || parseFloat(this.coords.width);
        height = height || parseFloat(this.coords.height);

        const sideBreakpoint = container.offsetWidth;
        const heightBreakpoint = container.offsetHeight + height * 0.6;

        const left = `${x}px`;
        const top = `${y}px`;

        this.tooltipVerticalOffset = Math
            .max(10, (y + heightBreakpoint) - window.innerHeight);

        if (!this.reversedChangingSide) {
            this.tooltipSide = x - sideBreakpoint < 80
                ? TooltipSide.RIGHT
                : TooltipSide.LEFT;
        } else {
            this.tooltipSide = x + width > window.innerWidth - 80 - sideBreakpoint
                ? TooltipSide.LEFT
                : TooltipSide.RIGHT;
        }

        this.coords = {
            ...this.defaultStyles,
            zIndex: this.defaultZIndex,
            left,
            top,
            width: `${width}px`,
            height: `${height}px`,
        };
    }

    updated() {
        if (
            prevEl !== this.focusElement
            || prevDay !== this.day
        ) {
            prevDay = this.day;
            prevEl = this.focusElement;
            this.updateTooltipPosition();
        }
    }

    setScrollContainer(e: HTMLElement | Window, keepWindowScroll = false) {
        this.currentScrollContainer = e;

        if (!keepWindowScroll) {
            window.removeEventListener('scroll', this.scrollHandler);
        }

        e.addEventListener('scroll', this.scrollHandler);
    }

    mounted() {
        window.addEventListener('scroll', this.scrollHandler);
    }

    beforeDestroy() {
        if (this.currentScrollContainer) {
            this.currentScrollContainer
                .removeEventListener('scroll', this.scrollHandler);
        }

        window.removeEventListener('scroll', this.scrollHandler);
    }

    private scrollHandler() {
        this.updateTooltipPosition();
    }
}
