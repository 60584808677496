









































import { Component, Vue, Prop } from 'vue-property-decorator';
import { IIndicatorValue } from './interfaces';

enum TOOLTIP_DIRRECTION {
    LEFT = 'left',
    RIGHT = 'right',
}

const VERTICAL_OFFSET = 20;

@Component
export default class CiTableTooltip extends Vue {
    @Prop({
        default: null,
    })
    elementRect!: DOMRect | null;

    @Prop({
        type: String,
        default: null,
    })
    title!: string | null;

    @Prop({
        type: String,
        default: null,
    })
    date!: string | null;

    @Prop({
        type: Array,
        default: null,
    })
    items!: IIndicatorValue[] | null;

    @Prop({
        type: Boolean,
        default: false,
    })
    isVisible!: boolean;

    get tooltipDirrection() {
        if (!this.elementRect) return TOOLTIP_DIRRECTION.LEFT;
        const tooltip = this.$refs.tooltip as HTMLElement;
        if (!tooltip) return TOOLTIP_DIRRECTION.LEFT;

        if ((tooltip.getBoundingClientRect().width + window.innerWidth / 4) > this.elementRect.x) {
            return TOOLTIP_DIRRECTION.RIGHT;
        }

        return TOOLTIP_DIRRECTION.LEFT;
    }

    get horizontalPos() {
        if (!this.elementRect) return null;
        const tooltip = this.$refs.tooltip as HTMLElement;
        if (!tooltip) return null;

        if (this.tooltipDirrection === TOOLTIP_DIRRECTION.RIGHT) {
            return { left: `${this.elementRect.right}px` };
        }

        return { left: `${this.elementRect.left - tooltip.getBoundingClientRect().width - VERTICAL_OFFSET}px` };
    }

    get verticalPos() {
        if (!this.elementRect) return null;
        const tooltip = this.$refs.tooltip as HTMLElement;
        if (!tooltip) return null;

        const tooltipRect = tooltip.getBoundingClientRect();

        const { top: elemTop } = this.elementRect;
        const offset = elemTop + VERTICAL_OFFSET + tooltipRect.height > window.innerHeight
            ? Math.abs(window.innerHeight - (elemTop + VERTICAL_OFFSET + tooltipRect.height))
            : 0;

        const top = `${this.elementRect.top - VERTICAL_OFFSET - offset}px`;

        return { top };
    }

    get arrowVerticalPos() {
        if (!this.elementRect || !this.verticalPos) return null;
        return { top: `${this.elementRect.top - parseInt(this.verticalPos.top, 10) + VERTICAL_OFFSET}px` };
    }
}
