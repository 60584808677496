










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CiTableActiveRow extends Vue {
    @Prop({
        type: Object,
        required: true,
    })
    activeRow!: {
        height: string | null
        offset: number | null
        scrollOffset: number | null
    };
}
