









































































import { Inject } from 'inversify-props';
import { Component, Prop } from 'vue-property-decorator';

import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ClipText from '@/modules/common/filters/clip-text.filter';
import DayTooltipTemplate from '@/modules/common/components/ui-kit/day-tooltip-template.vue';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import PromotionsService, { PromotionsServiceS } from '../promotions.service';
import { PromotionGraphType } from '../promotions.store';

interface TableData {
    value: number;
    valueString: number | string;
    hotelName: string;
    color: string;
    isMainHotel: boolean;
}

@Component({
    extends: DayTooltipTemplate,
})
export default class PromotionsGraphTooltip extends DayTooltipTemplate {
    @Inject(PromotionsServiceS)
    private promotionsService!: PromotionsService;

    @Inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    @Inject(DocumentFiltersServiceS)
    private documentFilterService!: DocumentFiltersService;

    @Prop({
        type: Object,
        required: true,
        default: () => ({}),
    })
    private hiddenGraphs!: { [hotelId: number]: boolean };

    public get isActive() {
        return !!this.day;
    }

    public get isDealsMode() {
        return this.promotionsService.graphType === PromotionGraphType.DEALS;
    }

    public get date() {
        const { year, month } = this.documentFilterService;

        if (!this.day) return '---';

        const d = new Date(year, month, this.day);
        const formatter = new Intl
            .DateTimeFormat('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        return formatter.format(d);
    }

    public get programView() {
        return this.promotionsService.programView
            && !this.promotionsService.programView.includes('percentage');
    }

    public get programLabel() {
        const { programView, provider } = this.promotionsService;

        if (!programView || !provider) return '';

        return this.promotionsService.getProgramLabel(provider, programView);
    }

    private get currentHotelId() {
        return +this.$route.params.hotelId;
    }

    public get tableData() {
        const { day } = this;
        const { provider, data } = this.promotionsService;

        if (!data || !provider) return [];

        const hotelColors = this.hotelsService
            .getHotelsGraphColor();

        return data.hotels
            .filter(hotelId => !this.hiddenGraphs[hotelId])
            .map(hotelId => {
                const programs = this.promotionsService
                    .getPrograms(day, provider, hotelId);

                const hotelName = this.hotelsService
                    .getHotelName(hotelId) || hotelId;

                const isMainHotel = this.currentHotelId === hotelId;

                const row = {
                    value: 0,
                    valueString: '-',
                    hotelName,
                    isMainHotel,
                    color: isMainHotel
                        ? CURRENT_HOTEL_GRAPH_COLOR
                        : hotelColors[hotelId],
                } as TableData;

                if (!programs) return row;

                const programList = Object.values(programs);

                if (this.isDealsMode) {
                    const percentageProgram = programList
                        .find(program => program.percentage !== undefined);

                    row.value = percentageProgram
                        ? percentageProgram.percentage
                        : 0;

                    row.valueString = percentageProgram
                        ? `${percentageProgram.percentage}%`
                        : '-';

                    return row;
                }

                row.value = programList
                    .filter(program => program.status)
                    .length;

                row.valueString = row.value || '-';

                return row;
            })
            .filter(row => (this.programView ? row.value : true))
            .sort((a, b) => b.value - a.value);
    }

    cutString(value: string) {
        return ClipText(value, 26);
    }
}
