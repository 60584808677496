















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import DEFAULT_LOS from '@/modules/document-filters/constants/default-los.constant';
import CustomCheckboxSelect, { Item } from '@/modules/common/components/ui-kit/custom-checkbox-select.vue';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

const MAX_SELECTED = 3;
const MIN_SELECTED = 1;

@Component({
    components: { CustomCheckboxSelect },
})
export default class LosDocumentFilter extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(UserServiceS) private userService!: UserService;

    @Prop({
        required: false,
        type: Boolean,
        default: false,
    })
    disable!: boolean;

    @Prop()
    private value!: number;

    @Prop({
        type: Boolean,
        default: true,
    })
    public customization!: boolean;

    private selectMode: boolean = true;
    private isShowError: boolean = false;
    private selectedCheckboxes: number[] = [];
    private errors: any = {};

    mounted() {
        this.selectedCheckboxItems = this.documentFiltersService.losItems.map(item => Number(item.value));
    }

    get isAdmin() {
        return this.userService.isAdmin;
    }

    get supportedLosNumber() {
        return MAX_SELECTED;
    }

    get minSelected() {
        return MIN_SELECTED;
    }

    set los(item) {
        const { model } = this.$vnode.data as { model: any };

        if (model) {
            this.$emit('input', item.value);
            return;
        }

        const los = item ? Number(item.value) : null;
        this.documentFiltersService.saveLos(los);
    }

    get los() {
        const { model } = this.$vnode.data as { model: any };

        let los: number | null;

        if (model) {
            los = this.value;
        } else {
            ({ los } = this.documentFiltersService.storeState.settings);
        }

        return this.losItems
            .find(item => item.value === los) || { name: '', value: undefined };
    }

    handleChangeCheckbox(val: number) {
        const newValue = [val];
        const difference = this.selectedCheckboxItems
            .filter(x => !newValue.includes(Number(x)))
            .concat(newValue.filter(x => !this.selectedCheckboxItems.includes(x)));
        this.selectedCheckboxItems = [...new Set(difference)];
    }

    get selectedCheckboxItems() {
        return this.selectedCheckboxes;
    }

    set selectedCheckboxItems(val: number[]) {
        this.selectedCheckboxes = val;
    }

    get losItems(): Item[] {
        const isRatesPage = this.$route.name ? this.$route.name.includes('rate') : false;
        const { losItems } = this.documentFiltersService;

        if (isRatesPage && this.ratesFiltersService.isScanDisabledProvider) {
            return losItems
                .map(item => ({
                    ...item,
                    name: String(`LOS${item.value}`),
                    isAutoscan: true,
                    isSelected: this.selectedCheckboxItems.findIndex(val => item.value === val) !== -1,
                }));
        }

        return (DEFAULT_LOS).map((los: number) => ({
            value: los,
            name: String(`LOS${los}`),
            isAutoscan: losItems.findIndex(item => los === Number(item.value)) !== -1,
            isSelected: this.selectedCheckboxItems.findIndex(item => los === item) !== -1,
        }));
    }

    handleClick() {
        this.selectMode = !this.selectMode;
    }

    handleCancel() {
        this.handleClick();
        const { losItems } = this.documentFiltersService;
        this.selectedCheckboxItems = losItems.map(item => Number(item.value));
    }

    handleError(errors: any) {
        this.showError = true;
        this.errors = { ...errors };
    }

    async handleUpdate() {
        if (this.selectedCheckboxItems.length === 0) {
            this.showError = true;
            this.errors = { minSelected: true };
            return;
        }
        await this.compsetsService.updateCompsetLos(this.selectedCheckboxItems);
        this.handleClick();
        this.showError = false;
    }

    get isSelectMode() {
        return this.selectMode;
    }

    get isMaxSelected() {
        return this.errors.maxSelected;
    }

    get isMinSelected() {
        return this.errors.minSelected;
    }

    get showError() {
        return this.isShowError;
    }

    set showError(val: boolean) {
        this.isShowError = val;
    }
}
