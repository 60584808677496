var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",on:{"mouseleave":function($event){_vm.isTooltip && !_vm.isFreezeTooltip && _vm.disableTooltip()}}},[[(_vm.type === 'line')?_c('LineGraph',{attrs:{"chartData":_vm.chartData,"options":_vm.updatedOptions,"applyPlugins":true,"plugins":this.plugins,"fixedTooltipPosition":_vm.canvasFixedTooltipPosition},on:{"afterDraw":_vm.handleDraw}}):_vm._e(),(_vm.type === 'horizontal-bar')?_c('HorizontalBarGraph',{attrs:{"chartData":_vm.chartData,"options":_vm.updatedOptions},on:{"afterDraw":_vm.handleDraw}}):_vm._e(),(_vm.type === 'bar')?_c('BarGraph',{attrs:{"chartData":_vm.chartData,"options":_vm.updatedOptions},on:{"afterDraw":_vm.handleDraw}}):_vm._e(),(_vm.type === 'rounded-bar')?_c('RoundedBarGraph',{attrs:{"chartData":_vm.chartData,"options":_vm.updatedOptions},on:{"afterDraw":_vm.handleDraw}}):_vm._e(),(_vm.type === 'doughnut')?_c('Doughnut',{attrs:{"chartData":_vm.chartData,"options":_vm.updatedOptions},on:{"afterDraw":_vm.handleDraw}}):_vm._e()],(_vm.isTooltip && _vm.tooltipX)?_c('div',{on:{"click":function($event){return _vm.handleClickTooltip()}}},[_c('div',{ref:"tooltip",staticClass:"my-tooltip",style:({
                left: _vm.tooltipX,
                top: _vm.tooltipY
            })},[_vm._t("tooltip",null,null,{
                    day: _vm.day, chartData: _vm.chartData,
                    freezeTooltip: _vm.freezeTooltip, tooltipModel: _vm.tooltipModel,
                    hoverIndex: _vm.hoverIndex, currentBarColor: _vm.currentBarColor,
                })],2)]):_vm._e(),(_vm.isTooltip && _vm.haveFixedTooltip)?_c('div',[(_vm.fixPosition)?_c('div',{staticClass:"fixed-tooltip",style:({ left: _vm.fixPosition })}):_vm._e(),(!_vm.fixPosition)?_c('div',{staticClass:"fixed-tooltip",style:({ right: _vm.fixedTooltipOffset || '2px' })}):_vm._e()]):_vm._e(),_c('div',{staticClass:"setupWidth"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }