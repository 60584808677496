









































































/* eslint-disable no-underscore-dangle */
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ChartData, ChartOptions, ChartTooltipModel } from 'chart.js';
import RoundedBarGraph from '@/modules/common/components/ui-kit/custom-graph/graph-types/rounded-bar';
import HorizontalBarGraph from './graph-types/horizontal-bar';
import BarGraph from './graph-types/bar';
import LineGraph from './graph-types/line';
import Doughnut from './graph-types/doughnut';

let prevTooltipElement: HTMLElement | null = null;

@Component({
    components: {
        LineGraph,
        HorizontalBarGraph,
        BarGraph,
        RoundedBarGraph,
        Doughnut,
    },
})
export default class CustomGraph extends Vue {
    @Prop({ required: true, type: Boolean })
    isTooltip!: boolean;

    @Prop({ required: false, type: Boolean })
    haveFixedTooltip!: boolean;

    @Prop({ required: false, type: String })
    fixedTooltipOffset!: string;

    @Prop({ required: true, type: Object as () => ChartData })
    chartData!: ChartData;

    @Prop({ required: false, type: Boolean, default: false })
    moveAlongTheYaxis!: boolean;

    @Prop({ required: false, type: Number, default: 0 })
    tooltipXOffset!: number;

    @Prop({ required: false, type: Number, default: 0 })
    tooltipYOffset!: number;

    @Prop({ required: false, type: Boolean, default: true })
    intersect!: boolean;

    @Prop({ required: true, type: Object as () => ChartOptions })
    options!: ChartOptions;

    @Prop({ required: false, type: String, default: null })
    defaultTooltipPos!: string;

    @Prop({ required: false, type: Function })
    onTooltipClick!: (tooltipPos: string) => void;

    @Prop({ required: false, type: Function as () => void })
    onDraw?: (chart: Chart) => void;

    @Prop({ required: false, type: Array })
    disabledTooltipDays!: number[];

    @Prop({ required: false, type: Boolean, default: false })
    isNeedDisable!: boolean;

    @Prop({ required: false, type: Boolean })
    // Fixed tooltip will be drawn via ctx
    isCanvasFixedTooltip?: boolean;

    @Prop({ required: false, type: Number })
    // Fixed tooltip clicked label index
    canvasFixedTooltipPosition?: number;

    @Prop({
        type: String,
        default: 'line',
    })
    type!: 'line' | 'horizontal-bar' | 'bar';

    tooltipX: string | null = null;
    tooltipY: string | null = null;
    fixPosition: string | null = this.defaultTooltipPos;
    isFreezeTooltip: boolean = false;
    day: string | null = null;
    currentBarColor: string = '#fff';
    hoverIndex: number = -1;
    tooltipModel: ChartTooltipModel | null = null;

    handleDraw(chart: Chart) {
        if (this.onDraw) {
            this.onDraw(chart);
        }
    }

    freezeTooltip(flag: boolean) {
        this.isFreezeTooltip = flag;
    }

    handleClickTooltip() {
        this.$emit('setDay', this.day);
        this.fixPosition = this.tooltipX;

        if (this.onTooltipClick && this.tooltipX) {
            this.onTooltipClick(this.tooltipX);
        }
    }

    get updatedOptions() {
        // without this.tooltipXOffset tooltip will not dynamycaly rerender
        // because no direct link to this prop in getter
        // eslint-disable-next-line no-unused-expressions
        this.tooltipXOffset;

        // eslint-disable-next-line no-unused-expressions
        this.tooltipYOffset;

        return this.isTooltip ? {
            ...this.options,
            onHover: (e: MouseEvent, activeElements: any) => {
                if (!activeElements[0]) return;

                const [chartElement] = activeElements;

                this.currentBarColor = chartElement._model.backgroundColor;
                this.hoverIndex = chartElement._index;

                if (this.options.onHover) {
                    (this.options.onHover as (e: MouseEvent, a: {}[]) => void)(e, activeElements);
                }
            },
            tooltips: {
                intersect: !this.intersect,
                mode: 'index',
                enabled: false,
                position: 'nearest',
                custom: this.showTooltip,
            },
        } : this.options;
    }

    get plugins() {
        const selectedPlugins = [] as string[];

        if (this.isCanvasFixedTooltip) {
            selectedPlugins.push('canvasFixedTooltipPlugin');
        }

        return selectedPlugins;
    }

    isDisabledDay() {
        if (!this.isNeedDisable) {
            return false;
        }

        const isHaveDisabledDays = !!this.disabledTooltipDays.length;

        if (!isHaveDisabledDays) {
            return false;
        }

        const isDayDisabled = this.disabledTooltipDays
            .includes(Number(this.day));

        if (!isDayDisabled) {
            return false;
        }

        return true;
    }

    showTooltip(tooltipModel: ChartTooltipModel): void {
        const targetPositionX = `${this.tooltipXOffset + tooltipModel.caretX - 15}px`;
        const targetPositionY = `${tooltipModel.caretY + this.tooltipYOffset}px`;

        this.tooltipModel = tooltipModel;

        if (tooltipModel.opacity === 0 && this.tooltipX !== targetPositionX) {
            this.tooltipX = null;
            this.tooltipY = null;
            return;
        }

        const isDisabled = this.isDisabledDay();

        this.freezeTooltip(false);

        this.tooltipX = isDisabled
            ? null
            : targetPositionX;

        if (this.moveAlongTheYaxis) {
            this.tooltipY = targetPositionY;
        }

        this.day = tooltipModel.title
            ? tooltipModel.title[0]
            : this.day;

        this.$emit('current-day', this.day);
        this.$forceUpdate();
    }

    disableTooltip(): void {
        this.tooltipX = null;
        this.$emit('current-day', null);
    }

    updated() {
        const { tooltip } = this.$refs as { tooltip: HTMLElement };

        if (tooltip !== prevTooltipElement) {
            prevTooltipElement = tooltip;
            this.$emit('tooltip-state-changed', tooltip && tooltip.children[0]);
        }
    }
}
