




















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class AnalysisBtn extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    })
    disabled!: boolean;

    private lastRoute: string | null = null;

    get currentRouteName() {
        if (!this.$route.name) {
            throw new Error('AnalysisBtn: empty this.$route.name!');
        }
        return this.$route.name;
    }

    get isAnalysisMode() {
        return this.currentRouteName.includes('.analysis');
    }

    get analysisRoute() {
        const { params } = this.$route;

        if (!this.isAnalysisMode) {
            this.lastRoute = this.currentRouteName;
            return {
                name: `${this.currentRouteName.replace(/\.(table.*|graph.*)/, '')}.analysis.table`.replace(/\.$/, ''),
                params,
            };
        }

        if (!this.lastRoute) {
            return {
                name: this.currentRouteName.replace('.analysis', ''),
                params,
            };
        }

        return {
            name: this.lastRoute,
            params,
        };
    }

    public toggleAnalysisMode() {
        this.$router.push(this.analysisRoute);
    }
}
