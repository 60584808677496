/* eslint no-underscore-dangle: 0 */
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { mixins, Bar } from 'vue-chartjs';
import {
    Chart,
    ChartData,
    ChartOptions,
    ChartTooltipOptions,
    ChartPluginsOptions,
} from 'chart.js';

export interface TooltipModel extends ChartTooltipOptions {
    opacity: number,
    caretX: number,
    title: string[]
}

@Component({
    extends: Bar,
    mixins: [mixins.reactiveProp],
})
export default class GraphBar extends Vue {
    @Prop({ required: true, type: Object as () => ChartData })
    chartData!: ChartData;

    @Prop({ required: true, type: Object as () => ChartOptions })
    options!: ChartOptions;
    @Watch('options')
    onOptionsChanged() {
        this.drawChart();
    }

    public renderChart!: (chartData: ChartData, options: ChartOptions) => void;
    public addPlugin!: (plugin: ChartPluginsOptions) => void;

    mounted() {
        const rounded = {
            id: 'roundedPlugin',
            afterDatasetsDraw: (chart: ChartPluginsOptions) => {
                const { ctx } = chart;
                if (!ctx) return;
                chart.config.data!.datasets!.forEach((element: any, i: any) => {
                    const { data } = element;
                    const meta = chart.getDatasetMeta(i);
                    meta.data.forEach((el: any) => {
                        const vm = el._model;
                        // const qwe = meta.data[i];

                        const halfWidth = vm.width / 2;
                        let rightX = vm.x + halfWidth;
                        let leftX = vm.x - halfWidth;
                        let top = vm.base - (vm.base - vm.y);
                        const halfStroke = vm.strokeWidth / 2;
                        let radius = halfWidth;

                        // Canvas doesn't allow us to stroke inside the width so we can
                        // adjust the sizes to fit if we're setting a stroke on the line
                        if (vm.showStroke) {
                            leftX += halfStroke;
                            rightX -= halfStroke;
                            top += halfStroke;
                        }

                        ctx.fillStyle = vm.backgroundColor;
                        ctx.strokeStyle = vm.borderColor;

                        // stop from creating funky shapes if the radius is bigger
                        // than the rectangle we are creating
                        if (radius > (vm.base - top) / 2) {
                            radius = (vm.base - top) / 2;
                        }

                        ctx.beginPath();
                        ctx.moveTo(leftX, vm.base - radius);
                        ctx.lineTo(leftX, top + radius);
                        ctx.quadraticCurveTo(leftX, top, leftX + radius, top);
                        ctx.lineTo(rightX - radius, top);
                        ctx.quadraticCurveTo(rightX, top, rightX, top + radius);
                        ctx.lineTo(rightX, vm.base - radius);
                        ctx.quadraticCurveTo(rightX, vm.base, rightX, vm.base);
                        ctx.lineTo(leftX + radius, vm.base);
                        ctx.quadraticCurveTo(leftX, vm.base, leftX, vm.base);
                        ctx.closePath();
                        ctx.restore();
                        ctx.fill();
                    });
                });
            },
        };

        // this.addPlugin(rounded);
        this.drawChart();
    }

    drawChart() {
        if (this.chartData && this.chartData.datasets && this.chartData.datasets.length !== 0) {
            this.renderChart(this.chartData, this.options);
        }
    }
}
