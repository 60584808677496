



































































































































import { Component, Mixins } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import DragScrollMixin from '@/modules/common/mixins/drag-scroll.mixin';
import ClipText from '@/modules/common/filters/clip-text.filter';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';

import PromotionsService, { PromotionsServiceS } from '../promotions.service';
import PromotionsMark from './promotions-mark.vue';
import PromotionsCalendar from './promotions-calendar-tooltip.vue';
import PromotionsPercentTooltip from './promotions-percent-tooltip.vue';

let isLoaded = false;

@Component({
    components: {
        PromotionsCalendar,
        PromotionsMark,
        PromotionsPercentTooltip,
    },
})
export default class PromotionsMarkTable extends Mixins(DragScrollMixin) {
    @Inject(PromotionsServiceS)
    private promotionsService!: PromotionsService;

    @Inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    @Inject(ProvidersServiceS)
    private providersService!: ProvidersService;

    public tooltipFocusElement: HTMLElement | null = null;
    public tooltipPercentFocusElement: HTMLElement | null = null;
    private unsubFromFilters: () => void = () => {};

    public get currentHotelId() {
        return +this.$route.params.hotelId;
    }

    public get hotelNames() {
        return Object.fromEntries(
            this.hotelsService.allHotels.map(d => [d.id, d.name]),
        );
    }

    public get isNoData() {
        if (this.isLoading) return false;
        if (!this.promotionsService.data) return true;

        return !Object.keys(this.promotionsService.data.providerData).length;
    }

    public get competitors() {
        if (this.isLoading) {
            // NOTE: Create some fake data to show skeleton
            return Array
                .from({ length: 5 })
                .map(_ => +this.$route.params.hotelId);
        }

        const { competitors } = this.promotionsService;

        if (competitors.length) {
            this.loadHotelNames();
        }

        return competitors;
    }

    public get isLoading() {
        return (this.promotionsService.isLoading)
            || !Object.keys(this.hotelNames).length;
    }

    public get skeleton() {
        return this.isLoading;
    }

    public get providerList() {
        if (this.isLoading) return ['loading'];
        if (!this.promotionsService.data) return [];

        return Object.keys(this.promotionsService.data!.providerData);
    }

    public get programList() {
        if (this.isLoading) {
            return ({
                loading: [
                    '_________________',
                    '________________',
                    '_______________',
                    '______________',
                    '__________________',
                ],
            });
        }

        if (!this.promotionsService.data) return {};

        return this.promotionsService.data.promotions;
    }

    private async loadHotelNames() {
        const hotels = this.promotionsService.data
            ? this.promotionsService.data.hotels
            : [];

        if (isLoaded) return;
        isLoaded = true;

        const promises = hotels
            .map(hid => this.hotelsService.getHotelName(hid));

        (await Promise.all(promises))
            .map((name, index) => [hotels[index], name]);
    }

    public cutString(s: string = '') {
        return ClipText(s, 23);
    }

    public getHotelName(hotelId: number) {
        if (!this.hotelNames) return hotelId;

        return this.cutString(this.hotelNames[hotelId]);
    }

    public setFocusElement(e: { currentTarget: HTMLElement }) {
        const { currentTarget } = e;

        this.tooltipFocusElement = currentTarget;
    }

    public resetFocusElement() {
        this.tooltipFocusElement = null;
    }

    public setPercentTooltipElement(element: HTMLElement) {
        this.tooltipPercentFocusElement = element;
    }

    public resetPercentTooltipElement() {
        this.tooltipPercentFocusElement = null;
    }

    public getProviderName(provider: string) {
        return this.providersService.getProviderLabel(provider);
    }

    public getProgramLabel = this.promotionsService
        .getProgramLabel.bind(this.promotionsService);

    public getProgramLogo = this.promotionsService
        .getProgramLogo.bind(this.promotionsService);

    public mounted() {
        const { container } = this.$refs as {
            [k: string]: any;
        };

        const resetTooltips = () => {
            this.resetFocusElement();
            this.resetPercentTooltipElement();
        };

        container.addEventListener('scroll', resetTooltips);

        this.unsubFromFilters = this.promotionsService
            .onFiltersChanged(resetTooltips);
    }

    public beforeDestroy() {
        this.unsubFromFilters();
    }
}
